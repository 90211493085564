@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--themes-colors-primary-background);
  transition: all .3s ease;

  &.agent-search {
    background-color: $color-transparent;

    &.scrolled {
      background-color: $color-white-20;
      backdrop-filter: blur(10px);
    }
  }

  &.home {
    background-color: $color-transparent;

    &.scrolled {
      background-color: $color-white-20;
      backdrop-filter: blur(10px);
    }

    &:not(.scrolled) {
      svg path {
        fill: $color-white;
      }

      button {
        border-color: $color-white;
        color: $color-white;

        &:hover {
          border-color: $color-black;
          background-color: $color-black;
          color: $color-white;
        }
      }

      .auth {
        &:hover {
          border-color: $color-white;
          background-color: $color-white;
          color: $color-black;
        }
      }

      .user {
        &:hover {
          svg path {
            fill: $color-black;
          }
        }
      }

      .search {
        border-color: $color-white;
        background-color: $color-white;
        color: $color-black;
        pointer-events: none; // Skip this button for Tab focus
      }

      .find-your-home {
        &:hover {
          .search {
            border-color: $color-black;
            background-color: $color-black;
            color: $color-white;
         }
        }
      }

      .menu {
        background-color: $color-white;
      }
    }
  }

  .layout-container {
    @include full-width-layout-container;
    position: relative;
    display: flex;
    height: $height-exp-top-nav;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    svg {
      max-width: 100%;
      height: 40px;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }

    &:hover {
      border-color: transparent;
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    button {
      position: relative;
      border: 1px solid $color-black;
      border-radius: 100px;
      margin-left: .7em;
      color: $color-black;
      font-weight: $font-weight-bold;

      svg {
        margin-right: 0;

        path {
          fill: $color-black;
        }
      }

      &.user {
        width: 40px;
        height: 40px;
        padding: 0;
      }

      &.active {
        background-color: $color-black;
        color: $color-white;

        svg path {
          fill: $color-white;
        }
      }

      &:hover {
        background-color: var(--themes-colors-primary-dark);
        color: $color-white;

        svg path {
          fill: $color-white;
        }
      }
    }

    .switcher {
      display: inline-block;
      margin-left: 1em;
    }

    .country-flag {
      display: flex;
      flex-direction: row;

      img {
        display: flex;
        width: 1.5em;
        height: 1.125em;
        margin-right: .4em;
        align-self: center;
      }

      span {
        margin-top: .1em; // offsets the not perfectly cut svg
      }
    }

    button span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dropdown-arrow-icon {
      width: .8em;
      height: .8em;
      margin-right: 0;
      margin-left: .3em;

      path {
        fill: var(--themes-colors-primary-font-color);
      }
    }
  }

  @include mobile {
    .layout-container {
      height: $height-top-nav-mobile;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
    }

    .logo {
      svg {
        height: 1em;
      }
    }
  }
}

.menu {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 0 .6em;

  .bar-container {
    display: flex;
    width: 22px;
    height: 10px;
    flex-direction: column;
    justify-content: space-between;
  }

  .bar {
    width: 100%;
    height: 2.5px;
    background-color: var(--themes-colors-primary-dark);
    transition: all 100ms ease-in-out;
  }

  &:hover {
    cursor: pointer;

    .bar {
      background-color: $color-white;
    }
  }

  .x:nth-of-type(1) {
    width: 20px;
    transform: rotate(45deg);
    transform-origin: 6.5px 3.5px;
    transition: all 100ms ease-in-out;
  }

  .x:nth-of-type(2) {
    width: 20px;
    transform: rotate(-45deg);
    transform-origin: 5px -1px;
    transition: all 100ms ease-in-out;
  }
}

.panel {
  position: absolute;
  top: 5em;
  right: 0;
  left: 0;
  padding-top: .5em;
  border-top: 1px solid $color-grey-4;
  margin: 0 auto;
  background-color: $color-white;

  &.no-background-color {
    background-color: transparent;
  }

  @include desktop {
    top: 4em;
    right: 25%;
    width: 31.25em;
    border: 1px solid $color-grey-4;
    border-radius: $border-radius-medium;
  }

  @media (min-width: $breakpoint-tablet) and (max-width: 900px) {
    right: 20px;
    left: auto;
  }
}

.full-screen-search {
  position: absolute;
  z-index: $z-index-full-screen-background;
  width: 100%;
  height: 100vh;
  background: $color-white;

  @include not-mobile {
    display: none;
  }
}
